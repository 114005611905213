<header>
  <div nz-row>
    <div class="left-new" nz-col nzSpan="12"><a href="https://www.gov.br/pt-br" target="_blank"><img src="assets/img/Gov.br_logo.svg" alt=""></a></div>

    <div class="right-new" nz-col nzSpan="12">
      <ng-container *ngIf="user && isButtonVisible">
        <div class="dadge" [nzTrigger]="'click'">
            <nz-badge [nzCount]="!loadingNotificacoes ? notificacoesNaoLidasCount() : iconTemplate" [nzOverflowCount]="50">
                <button class="user-icon" nz-dropdown [nzDropdownMenu]="menuDropdown" [nzPlacement]="'bottomRight'" (click)="loadNotificacoes()">
           
                    <div class="menu-letra">{{user?.no_usuario?.substr(0, 1)}}</div>
                </button>
            </nz-badge>
            <ng-template #iconTemplate>
                <i nz-icon nzType="clock-circle" class="ant-scroll-number-custom-component" style="color: #f5222d"></i>
              </ng-template>
        </div>

        <nz-dropdown-menu #menuDropdown="nzDropdownMenu" nzClickHide="false">
            <div class="custom-menu-content">
                <div>
                    <span class="username">{{user.no_usuario}}</span>
                    <span class="user-detail">{{user.ds_email}}</span>
                    <span class="user-detail">{{user.ds_login}}</span>
                </div>

                <nz-menu nzMode="horizontal">
                  <nz-menu-item>
                    <a title="Alterar Senha" [routerLink]="['alterar-senha']">Alterar senha</a>
                  </nz-menu-item>
                 
                  <nz-menu-item>
                    <a href="mailto:atendimento+pnldavaliacao@nees.ufal.br?subject=Reportar problema" target="_blank">Reportar
                        um problema</a>
                  </nz-menu-item>
                  
                  <nz-menu-item>
                    <a  (click)="logout()">Sair</a>
                  </nz-menu-item>
                </nz-menu>

                <div *ngIf="loadingNotificacoes">
                    <nz-alert nzType="warning" nzMessage="Atualizando as notiticações. Aguarde..." nzShowIcon></nz-alert>
                </div>
                <nz-tabset nzType="line">
                    <nz-tab [nzTitle]="'Não lidas (' + notificacoesNaoLidas?.length + ')'">
                        <ul nz-list [nzDataSource]="notificacoesNaoLidas" nzBordered nzSize="large" class="scrollable-list">
                            <li nz-list-item *ngFor="let item of notificacoesNaoLidas; let i = index" nzNoFlex>
                                <div class="box-titulo">
                                    <div class="titulo clickable" (click)="showModalNotificacoes(notificacoesNaoLidas, i, 'nao-lidas')">{{ item.titulo }}</div>
                                    <div class="notifications-actions" *ngIf="user.co_usuario == item.notifiable_id || canAcoesNotificacao">
                                        <span nz-icon nzType="check-circle" nzTheme="outline" (click)="marcarComoLida(item)"
                                            nz-tooltip nzTooltipTitle="Marcar como lida"></span>
                                        <span nz-icon nzType="book" nzTheme="outline" (click)="marcarComoSalva(item)"
                                            nz-tooltip nzTooltipTitle="Marcar como salva"></span>
                                        <span nz-icon nzType="delete" nzTheme="outline" (click)="marcarComoExcluida(item)"
                                            nz-tooltip nzTooltipTitle="Excluir"></span>
                                    </div>
                                </div>
                                <div class="notificacao-tempo">{{item.created_at | formatData }}</div>
                                <div class="notificacao-tempo">{{item.created_at | formatData }}</div>

                                <div class="mensagem clickable" (click)="showModalNotificacoes(notificacoesNaoLidas, i, 'nao-lidas')">{{ item.mensagem }}</div>
                            </li>
                        </ul>
                    </nz-tab>
                    <nz-tab [nzTitle]="'Lidas (' + notificacoesLidas?.length + ')'">
                        <ul nz-list [nzDataSource]="notificacoesLidas" nzBordered nzSize="large" class="scrollable-list">
                            <li nz-list-item *ngFor="let item of notificacoesLidas; let i = index" nzNoFlex>
                                <div class="box-titulo">
                                    <div class="titulo clickable" (click)="showModalNotificacoes(notificacoesLidas, i, 'lidas')">{{ item.titulo }}</div>
                                    <div class="notifications-actions" *ngIf="user.co_usuario == item.notifiable_id || canAcoesNotificacao">
                                        <span nz-icon nzType="book" nzTheme="outline" (click)="marcarComoSalva(item)"
                                            nz-tooltip nzTooltipTitle="Marcar como salva"></span>
                                        <span nz-icon nzType="delete" nzTheme="outline" (click)="marcarComoExcluida(item)"
                                            nz-tooltip nzTooltipTitle="Excluir"></span>
                                    </div>
                                </div>
                                <div class="notificacao-tempo">{{item.created_at | formatData }}</div>
                                <div class="mensagem clickable" (click)="showModalNotificacoes(notificacoesNaoLidas, i, 'lidas')">{{ item.mensagem }}</div>
                            </li>
                        </ul>
                    </nz-tab>
                    <nz-tab [nzTitle]="'Salvas (' + notificacoesSalvas?.length + ')'">
                        <ul nz-list [nzDataSource]="notificacoesSalvas" nzBordered nzSize="large" class="scrollable-list">
                            <li nz-list-item *ngFor="let item of notificacoesSalvas; let i = index" nzNoFlex>
                                <div class="box-titulo">
                                    <div class="titulo clickable" (click)="showModalNotificacoes(notificacoesSalvas, i, 'salvas')">{{ item.titulo }}</div>
                                    <div class="notifications-actions" *ngIf="user.co_usuario == item.notifiable_id || canAcoesNotificacao">
                                        <span nz-icon nzType="delete" nzTheme="outline" (click)="marcarComoExcluida(item)"
                                            nz-tooltip nzTooltipTitle="Excluir"></span>
                                    </div>
                                </div>
                                <div class="notificacao-tempo">{{item.created_at | formatData }}</div>
                                <div class="mensagem clickable" (click)="showModalNotificacoes(notificacoesSalvas, i, 'salvas')">{{ item.mensagem }}</div>
                            </li>
                        </ul>
                    </nz-tab>
                </nz-tabset>

                <nz-menu nzMode="horizontal" class="footer-links">
                    <nz-menu-item>
                      <a href="assets/pdf/Politica_de_Privacidade_PNLD_Avaliacao.pdf" target="_blank">Política de Privacidade</a>
                    </nz-menu-item>
                    <nz-menu-item>
                      <a href="assets/pdf/Termos_de_Uso_PNLD_Avaliacao.pdf" target="_blank">Termos de uso</a>
                    </nz-menu-item>
                </nz-menu>

            </div>          
        </nz-dropdown-menu>
      </ng-container>
    </div>
  </div>

  <div nz-row>
    <div *ngIf="isButtonCollapsed" nz-col nzFlex="50px">
      <button class="hmenu" (click)="onCollapsed.emit()">
        <i nz-icon nzType="menu" nzTheme="outline"></i>
      </button>
    </div>
    <div nz-col nzFlex="auto">
      <strong>Programa Nacional do Livro e do Material Didático</strong> <br />
      <span class="subtitulo">Plataforma de Avaliação Pedagógica</span>
    </div>
  </div>
</header>
<div class="to-top" [ngClass]="{ 'show-scrollTop': scrolled }">
  <a nz-button nzShape="circle" (click)="scrollToTop()" title="Voltar ao topo">
    <i nz-icon nzType="arrow-up" nzTheme="outline"></i>
  </a>
</div>

<nz-modal
    [(nzVisible)]="isVisibileModalNotificacoes"
    [nzTitle]="modalTitle"
    [nzContent]="modalContent"
    [nzFooter]="modalFooter"
    (nzOnCancel)="closeModalNotificacoes()"
    [nzWidth]="800"
    [ngStyle]="{'height': '800px', 'min-height': '600px', 'max-height': '600px', 'min-width': '800px'}">

    <div class="modal-content">
      <ng-template #modalTitle>
          <div>
            {{notificacaoSelected?.titulo}}
          </div>
          <div class="notificacao-tempo">{{notificacaoSelected?.created_at | formatData }}</div>
          <div class="notificacao-tempo">{{notificacaoSelected?.co_notificacao }}</div>
      </ng-template>

      <ng-template #modalContent>
          <div class="scrollable-text">
            <p>{{notificacaoSelected?.mensagem}}</p>

            <div *ngIf="notificacaoSelected?.titulo_detalhes">
              <p>
                <strong>
                  {{notificacaoSelected?.titulo_detalhes}}
                </strong>
              </p>
            
              <p>{{notificacaoSelected?.detalhes}}</p>
            </div>
          </div>

          <div *ngIf="notificacaoSelected?.link">
              <a href="{{notificacaoSelected?.link?.href}}">{{notificacaoSelected?.link?.titulo}}</a>
          </div>
          <div class="button-container-modal" *ngIf="notificacaoSelected.notifiable_id == user.co_usuario || canAcoesNotificacao">
              <button nz-button nzType="primary" (click)="marcarComoLida(notificacaoSelected)" *ngIf="notificacaoSelectedListTipo != 'lidas'">Lida</button>
              <button nz-button nzType="primary" (click)="marcarComoSalva(notificacaoSelected)" *ngIf="notificacaoSelectedListTipo != 'salvas'">Salvar</button>
              <button nz-button nzType="primary" (click)="marcarComoExcluida(notificacaoSelected)">Excluir</button>
          </div>
      </ng-template>

      <ng-template #modalFooter>
          <div class="button-container-modal-footer">
              <div>
                <button nz-button nzType="default" (click)="anteriorNotificacao(true)">Primeira</button>
                <button nz-button nzType="default" (click)="anteriorNotificacao()">Anterior</button>
              </div>
              
              <div>
                <button nz-button nzType="default" (click)="proximaNotificacao()">Próxima</button>
                <button nz-button nzType="default" (click)="proximaNotificacao(true)">Última</button>
              </div>
          </div>
      </ng-template>
    </div>
</nz-modal>